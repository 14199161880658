import React, { useContext, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AppLayout,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  SideNavigation,
  SideNavigationProps,
} from "@amzn/awsui-components-react/polaris";
import { I18nProvider } from "@amzn/awsui-components-react/polaris/i18n";
import messages from "@amzn/awsui-components-react/polaris/i18n/messages/all.en";

import MetricsPanel from "./MetricsPanel";

import ControlsTableForWorkbench from "./ControlsTableForWorkbench";
import RiskTableForWorkbench from "./RiskTableForWorkbench";
import IncidentsTabsForWorkbench from "./incidents/IncidentTabsForWorkbench";
import { EditIncidentContextProvider } from "./incidents/EditIncident/useEditIncident";
import ViewIncidentModal from "./incidents/ViewIncidentModal";
import ConsequenceTable from "./ConsequenceTableForWorkbench";
import EditIncidentModal from "./incidents/EditIncident/EditIncidentModal";
import EditControlModal from "./EditControlModal";

import EditRiskCategoryModal from "./EditRiskCategoryModal";
import EditSubRiskModal from "./EditSubRiskModal";
import CreateControlModal from "./CreateControlModal";
import { RoleContext } from "./RoleProvider";
import AsmsAndRsmsTable from "./AsmsAndRsms/AsmsAndRsmsTable"
import { RegionsAorsCountriesProvider } from "./AsmsAndRsms/useRegionsAorsCountries";
import { FlashMessageContext } from "./useFlashMessages";
import { SiteContextProvider } from "./HomePage/useNewTabbedView";
import HomePageSwitcher from "./HomePage/HomePageSwitcher";

const LOCALE = "en";

const PrimaryAppLayout = () => {
  const [navOpen, setNavOpen] = useState(false);

  const { userIsRiskAdmin } = useContext(RoleContext);
  const { showSuccess, setShowSuccess, flashMessage } = useContext(FlashMessageContext);

  const navOptions: SideNavigationProps.Item[] = [{ type: "link", text: "Landing page", href: "/" }];
  if (userIsRiskAdmin) {
    navOptions.push({ type: "link", text: "Global risk register (workbench)", href: "/globalRiskRegister" });
    navOptions.push({ type: "link", text: "Global controls register (workbench)", href: "/globalControlsRegister" });
    navOptions.push({ type: "link", text: "Security incident database (workbench)", href: "/securityIncidentDatabase" });
    navOptions.push({ type: "link", text: "Amazon Corporate Security (ACS) contacts", href: "/SecurityManagers" })
  }

  return (
    <>
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '',
                        text: "Ballast map",
                        /*logo: { alt: "logo", src: isDarkMode ? light : dark }*/
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  notifications={showSuccess && <Flashbar
                    items={[
                      {
                        type: 'success',
                        dismissible: true,
                        content: flashMessage,
                        id: 'message_1',
                        onDismiss: () => {
                          setShowSuccess(false);
                        }
                      },
                    ]}

                  />}

                  content={<>
                    <ViewIncidentModal />
                    <ContentLayout
                      disableOverlap
                    >
                      <MetricsPanel />
                      <SiteContextProvider>
                        <HomePageSwitcher />
                      </SiteContextProvider>
                    </ContentLayout>
                  </>}
                />}
            />
            <Route
              path="/securityIncidentDatabase"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/securityIncidentDatabase',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <ViewIncidentModal />
                    <EditIncidentContextProvider>
                      <EditIncidentModal />
                    </EditIncidentContextProvider>

                    <Container
                      header={
                        <Header variant="h1">
                          <span className="poseiden">Security incident database (workbench)</span>
                        </Header>
                      }
                    >
                      <IncidentsTabsForWorkbench />
                    </Container>
                  </>}
                />}
            />
            <Route
              path="/globalControlsRegister"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/globalControlsRegister',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <EditControlModal />
                    <CreateControlModal />
                    <Container
                      header={<Header variant="h2"> <span className="poseiden">Global controls register (workbench)</span></Header>} variant="stacked"
                    >
                      <ControlsTableForWorkbench />
                    </Container>
                  </>}
                />}
            />
            <Route
              path="/globalRiskRegister"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/globalRiskRegister',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <EditRiskCategoryModal />
                    <EditSubRiskModal />
                    <Container
                      header={<Header variant="h2"><span className="poseiden">Global risk register (workbench)</span></Header>} variant="stacked"
                    >
                      <RiskTableForWorkbench />
                    </Container>

                  </>}
                />}
            />
            <Route
              path="/consequenceWorkbench"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: '/consequenceWorkbench',
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <Container
                      header={<Header variant="h2"><span className="poseiden">Consequences Workbench</span></Header>} variant="stacked"
                    >
                      <ConsequenceTable />
                    </Container>
                  </>}
                />}
            />

            <Route
              path="/SecurityManagers"
              element={
                <AppLayout
                  navigationOpen={navOpen}
                  onNavigationChange={({ detail }) => setNavOpen(detail.open)}
                  navigation={
                    <SideNavigation
                      header={{
                        href: "/SecurityManagers",
                        text: "Ballast map",
                      }}
                      items={navOptions}
                    />
                  }
                  toolsHide={true}
                  content={<>
                    <Container
                      header={<Header><span className="poseiden">Amazon Corporate Security (ACS) contacts</span></Header>}
                    >
                      <RegionsAorsCountriesProvider>
                        <AsmsAndRsmsTable />
                      </RegionsAorsCountriesProvider>
                    </Container>
                  </>}
                />}
            />
          </Routes>
        </BrowserRouter>
      </I18nProvider>
    </>
  );
}

export default PrimaryAppLayout;
