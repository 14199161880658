import React, { useContext, useEffect, useState } from "react";
import { Box, ColumnLayout, Container, Icon, Popover, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { EntityAndManagerContext } from "./EntityAndManagerProvider";

const consideredScore: any = (float: number) => {
  if (Number.isNaN(float)) {
    return (<span style={{ color: "red" }}>None</span>);
  }
  const int = Math.round(float);
  if (int === 0) {
    return (<span style={{ color: "gray" }}>Doesn't exist</span>);
  } else if (int >= 1 && int < 4) {
    return (<span style={{ color: "green" }}>Insignificant</span>);
  } else if (int >= 5 && int < 9) {
    return (<span style={{ color: "green" }}>Low</span>);
  } else if (int >= 10 && int < 15) {
    return (<span style={{ color: "orange" }}>Moderate</span>);
  } else if (int >= 16 && int < 24) {
    return (<span style={{ color: "red" }}>High</span>);
  } else if (int <= 25) {
    return (<span style={{ color: "red" }}>Extreme</span>);
  }
};




export default () => {
  const { entities, siteCountsByRegion } = useContext(EntityAndManagerContext);
  const [incidentsLast90, setIncidentsLast90] = useState(0);
  const [aggregatedOverallScore, setAggregatedOverallScore] = useState(0);

  useEffect(() => {
    if (entities.length > 0) {
      let incidents = 0;
      let scoreTotal = 0;
      let effectivenessTotal = 0;
      // TODO: these three properties are numbers, but they appear here as strings like "2", so I have this hack here where I coerce the number
      // into a string and then parseInt.  I should figure out why this is needed.
      entities.forEach((entity) => {
        if (entity.i90) {
          incidents += parseInt(entity.i90 + "");
        }
        if (entity.site_risk_avg) {
          scoreTotal += parseInt(entity.site_risk_avg + "");
        }
        if (entity.avg_effect) {
          effectivenessTotal += parseInt(entity.avg_effect + "");
        }
      });
      setIncidentsLast90(incidents);

      const nEntitiesWithSiteRiskAvg = entities.filter((ent) => ent.site_risk_avg !== null).length;
      setAggregatedOverallScore(nEntitiesWithSiteRiskAvg > 0 ? Math.round(scoreTotal / entities.filter((ent) => ent.site_risk_avg !== null).length) : 0);
    }
  }, [entities.length]);

  function getOverallRiskScoreText(score: number) {
    if (score === 0) return "(Doesn't Exist)";
    if (score < 5) return "(Insignificant)";
    if (score < 10) return "(Low)";
    if (score < 16) return "(Moderate)";
    if (score < 25) return "(High)";
    if (score === 25) return "(Extreme)";
    return "";
  }

  const timeWashingtonDC = new Date().toLocaleTimeString('en-US', { timeZone: 'America/New_York', hour: "numeric", minute: "numeric" });
  const timeLondon = new Date().toLocaleTimeString('en-US', { timeZone: 'Europe/London', hour: "numeric", minute: "numeric" });
  const timeBangalore = new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour: "numeric", minute: "numeric" });
  const timeSeattle = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour: "numeric", minute: "numeric" });
  return (
    <Container>
      <ColumnLayout columns={3} variant="text-grid" minColumnWidth={170}>
        {siteCountsByRegion[0].site_count > 0 ?
          <Table
            columnDefinitions={[
              {
                id: "region",
                header: <Box fontWeight="bold" textAlign="center" variant="div">Region</Box>,
                cell: item => <Box textAlign="center">{item.region}</Box>,
              },
              {
                id: "site_count",
                header: <Box fontWeight="bold" textAlign="center" variant="div" >Site count</Box>,
                cell: item => <Box textAlign="center">{item.site_count}</Box>,
              },
            ]}
            enableKeyboardNavigation
            items={siteCountsByRegion}
            sortingDisabled
          /> :
          <Box fontSize="display-l" fontWeight="bold" padding={{ top: "l" }} textAlign="center">
            <Icon name="status-in-progress" size="large" />
          </Box>}
        <Box textAlign="center"  padding="xxl">
        <p>
          <a href="#">View Global Risk Register</a>
        </p>
          <p>
            <a href="#">View Global Control Register</a>
          </p>
        </Box>

        <Box >
          <SpaceBetween size="m" >
            <Box textAlign="right">Washington DC: <strong>{timeWashingtonDC}</strong></Box>
            <Box textAlign="right">Seattle: <strong>{timeSeattle}</strong></Box>
            <Box textAlign="right">Bangalore: <strong>{timeBangalore}</strong></Box>
            <Box textAlign="right">London: <strong>{timeLondon}</strong></Box>
          </SpaceBetween>
        </Box>
      </ColumnLayout>
    </Container>
  );
};